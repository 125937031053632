<template>
  <div class="my-publish-container">
    <Title name='参与的竞买'></Title>
    <div class="filter-content">
      <el-form
        inline
        label-width="80px">
        <el-form-item label="发布时间:">
          <el-date-picker
            v-model="filterForm.dateRang"
            @change="handleDateRangChange"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="">
          <el-button size="small" @click="getProductList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tabel-content">
      <el-table
        :header-cell-style="{'background':'#f5f5f5','font-weight': 500,'color':'#666','padding':'12 5px'}"
        :data="tableData"
        style="width: 100%">
        <el-table-column
          prop="addTime"
          label="发布时间"
          width="180">
        </el-table-column>
        <el-table-column
          prop="productName"
          label="产品名称"
          width="180">
        </el-table-column>
        <el-table-column
          prop="categoryName"
          width="180"
          label="所属类型">
        </el-table-column>
        <el-table-column
          prop="productSpec"
          label="规格型号">
        </el-table-column>
        <el-table-column
          prop="reqNum"
          label="需求量">
        </el-table-column>
        <el-table-column
          prop="unit"
          label="单位">
        </el-table-column>
        <el-table-column
          prop="maxPrice"
          width="100"
          label="最高竞买价">
        </el-table-column>
        <!--<el-table-column-->
          <!--prop="apprvStatus"-->
          <!--label="状态">-->
          <!--<template slot-scope="scope">-->
            <!--{{scope.row.apprvStatus | getApprvStatus}}-->
          <!--</template>-->
        <!--</el-table-column>-->
        <el-table-column
          prop="startTime"
          width="180"
          label="开始时间">
        </el-table-column>
        <el-table-column
          prop="validDate"
          width="180"
          label="到期时间">
        </el-table-column>
        <!--<el-table-column-->
          <!--fixed="right"-->
          <!--label="操作"-->
          <!--align="center">-->
          <!--<template slot-scope="scope">-->
            <!--<el-button v-if="scope.row.apprvStatus === 'P'" size="small" type="text" @click="toDetail(scope.row.id)">查看报价</el-button>-->
          <!--</template>-->
        <!--</el-table-column>-->

        <el-table-column
                fixed="right"
                label="操作"
                align="center">
          <template slot-scope="scope">
            <el-button v-if="scope.row.apprvStatus === 'P'" size="small" type="text" @click="toDetail(scope.row.id, scope.row.unit)">查看</el-button>
          </template>
        </el-table-column>

      </el-table>
      <div class="pagination" v-if="pageManage.total">
        <el-pagination
          background layout="prev, pager, next"
          :total='pageManage.total'
          :page-size='pageManage.limit'
          :current-page="pageManage.page"
          @current-change="changeCurrentPage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMyPartProduct,
  withdrawProduct
} from '../../api';
import Title from '../../component/Title'
export default {
  components: {
    Title
  },
  data() {
    return {
      productPictureManage: {
        dialogImageUrl: '',
        dialogVisible: false
      },
      filterForm: {
        type: 1,
        fromDate: null,
        toDate: null,
        dateRang: []
      },
      pageManage: {
        limit: 10,
        page: 1,
        total: 0
      },
      tableData: []
    }
  },
  created() {
    this.$store.commit('changeMenuType', 'SELL');
    this.getProductList();
  },
  filters: {
    getApprvStatus(val) {
      switch(val) {
        case 'A':
          return '待审核';
          break;
        case 'P':
          return '已通过';
          break;
        case 'F':
          return '已拒绝';
          break;
      }
    }
  },
  methods: {
    onWithdraw(id) {
      this.$confirm('此操作将撤回竞买信息, 是否继续?', '立即发布', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        withdrawProduct({id}).then(res => {
          if (res.errno === 0) {
            this.$message({
              type: 'success',
              message: '撤回成功！'
            });
            this.getProductList();
          }
        });
      });
    },
    toEdit(id) {
      this.$router.push({
        path: '/User/Publish',
        query: {
          id,
          isEdit: true,
        }
      })
    },
    toDetail(id, unit) {
      this.$router.push({
        path: '/User/PublishDetail',
        query: {
            id:id,
            viewFlag: true,
            unit:unit
        }
      })
    },
    getProductList() {
      getMyPartProduct({
        ...this.filterForm,
        ...this.pageManage,
      }).then(res => {
        this.tableData = res.data.myPartProduct;
        this.pageManage.total = res.data.total;
      });
    },
    changeCurrentPage(num){
      this.pageManage.page = num;
      this.getProductList();
    },
    handleDateRangChange(value) {
      this.filterForm.fromDate = value ? value[0] : null;
      this.filterForm.toDate = value ? value[1] : null;
    }
  }
}
</script>

<style lang="less">
.my-publish-container {
  background: #fff;

  .filter-content {
    padding: 0 10px;
    box-sizing: border-box;
  }
  .tabel-content {
    padding: 10px;
    box-sizing: border-box;
  }
  .pagination {
    padding: 20px;
    box-sizing: border-box;
    text-align: right;
  }
}
</style>
